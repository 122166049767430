import * as React from "react"

// styles
const pageStyle = {
  color: "#000",
  background: "#000",
  padding: "0px",
  height:"1000px",
}


// markup
const blackOutPage = () => {
  return (
    <main style={pageStyle}>
    </main>
  )
}

export default blackOutPage
